import React, { useState, useMemo } from 'react';
import { gql } from '@apollo/client';
import classNames from 'classnames';
import { Text, Stack, Button } from '@moda/om';
import { compact, Vertical, VERTICALS } from '@moda/portal-stanchions';
import { VariantCellAddToBagFragment } from '../../../../../generated/types';
import { getActiveVariantBy } from '../../../../../lib/getActiveVariantBy';
import { useAddToBag, ADD_TO_BAG_FRAGMENT } from '../../../../../hooks/useAddToBag';
import { hrefFor } from '../../../../../routers';
import { VariantCellSizeSlider, VARIANT_CELL_SIZE_SLIDER_FRAGMENT } from '../VariantCellSizeSlider';

import './VariantCellAddToBag.scss';

export const VARIANT_CELL_ADD_TO_BAG_FRAGMENT = gql`
  fragment VariantCellAddToBagFragment on Variant {
    designerSlug: designer_slug
    gender
    productSlug: slug
    ...VariantCellSizeSliderFragment
    ...AddToBagFragment
  }
  ${VARIANT_CELL_SIZE_SLIDER_FRAGMENT}
  ${ADD_TO_BAG_FRAGMENT}
`;

type Props = {
  className?: string;
  variant: VariantCellAddToBagFragment;
  activeVariantId: string;
  position?: number;
  href: string;
  isSpectacularGift: boolean;
  isShoppableEditItem?: boolean;
  shoppableEditItemSize?: string;
  shoppableEditItemQty?: number;
};

export const VariantCellAddToBag: React.FC<Props> = ({
  className,
  variant,
  activeVariantId,
  position = 1,
  href,
  isSpectacularGift = false,
  isShoppableEditItem = false,
  shoppableEditItemSize,
  shoppableEditItemQty
}) => {
  const [selectedSize, setSelectedSize] = useState<string | undefined>(
    isShoppableEditItem ? shoppableEditItemSize : undefined
  );
  const activeVariant = useMemo(
    () => getActiveVariantBy(variant, 'id', activeVariantId),
    [activeVariantId, variant]
  );

  const selectedInventory = useMemo(
    () => compact(activeVariant.inventory).find(({ size }) => size === selectedSize),
    [activeVariant.inventory, selectedSize]
  );

  const { text, handleAddToBag, isOneSizedItem, disabled } = useAddToBag({
    activeVariantId,
    variant,
    position,
    size:
      activeVariant.inventory.length === 1 && activeVariant.inventory[0]?.size === 'OS'
        ? activeVariant.inventory[0]
        : selectedInventory,
    href,
    validatesSizePresenceOn: 'submit',
    quantity: isShoppableEditItem && shoppableEditItemQty != null ? shoppableEditItemQty : 1
  });

  const vertical = VERTICALS[variant.gender as Vertical].toLowerCase();

  return (
    <Stack
      className={classNames('VariantCellAddToBag', className)}
      space={0}
      justifyContent="center"
      alignItems="center"
    >
      {isSpectacularGift ? (
        <Button
          className="VariantCellAddToBag__button"
          secondary
          to={hrefFor.ProductDetailPage({
            vertical,
            variantId: variant.id,
            designerSlug: variant.designerSlug || '',
            productSlug: variant.productSlug
          })}
        >
          LEARN MORE
        </Button>
      ) : isShoppableEditItem ? (
        <Button
          className="VariantCellAddToBag__button"
          secondary
          disabled={disabled}
          onClick={() => handleAddToBag()}
        >
          {text}
        </Button>
      ) : isOneSizedItem && !isSpectacularGift ? (
        <Button
          className="VariantCellAddToBag__button"
          secondary
          disabled={disabled}
          onClick={() => handleAddToBag()}
        >
          {text}
        </Button>
      ) : (
        <>
          <Text treatment="body2">{text}</Text>
          <VariantCellSizeSlider
            variant={variant}
            activeVariantId={activeVariantId}
            onSelectSize={setSelectedSize}
            onClick={() => handleAddToBag()}
          />
        </>
      )}
    </Stack>
  );
};

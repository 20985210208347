import React, { useMemo, useState } from 'react';
import { gql } from '@apollo/client';
import classNames from 'classnames';
import { Badge, Clickable, Stack } from '@moda/om';
import { VariantCellDetailsFragment } from '../../../../../generated/types';
import { getActiveVariantBy } from '../../../../../lib/getActiveVariantBy';
import { Link } from '../../../../Link';
import { CollectionBadge } from '../../../../CollectionBadge';
import { isSpectacularGift } from '../../../../../lib/isSpectacularGift';
import {
  VariantCellProductPrice,
  VARIANT_CELL_PRODUCT_PRICE_FRAGMENT
} from '../VariantCellProductPrice';
import { VariantCellAddToBag, VARIANT_CELL_ADD_TO_BAG_FRAGMENT } from '../VariantCellAddToBag';
import {
  VariantCellColorSelector,
  VARIANT_CELL_COLOR_SELECTOR_FRAGMENT
} from '../VariantCellColorSelector';

import './VariantCellDetails.scss';
import { useSpectacularGiftFlag } from '../../../../../hooks/useSpectacularGiftFlag';

export const VARIANT_CELL_DETAILS_VARIANT_FRAGMENT = gql`
  fragment VariantCellDetailsVariantFragment on VariantData {
    id
    label
    collections
  }
`;

export const VARIANT_CELL_DETAILS_FRAGMENT = gql`
  fragment VariantCellDetailsFragment on Variant {
    id
    name
    designerName: designer_name
    tag
    masterVariant: master_variants_data {
      ...VariantCellDetailsVariantFragment
    }
    otherVariants: other_variants_data {
      ...VariantCellDetailsVariantFragment
    }
    shopAttributes: shop_attributes
    ...VariantCellProductPriceFragment
    ...VariantCellAddToBagFragment
    ...VariantCellColorSelectorFragment
  }
  ${VARIANT_CELL_DETAILS_VARIANT_FRAGMENT}
  ${VARIANT_CELL_PRODUCT_PRICE_FRAGMENT}
  ${VARIANT_CELL_ADD_TO_BAG_FRAGMENT}
  ${VARIANT_CELL_COLOR_SELECTOR_FRAGMENT}
`;

type Props = {
  variant: VariantCellDetailsFragment;
  activeVariantId: string;
  href: string;
  active?: boolean;
  isShoppableEditItem?: boolean;
  shoppableEditItemSize?: string;
  shoppableEditItemColor?: string;
  shoppableEditItemQty?: number;
  trackHrefClick: () => void;
  onSelectColor: (color: string) => void;
};

export const VariantCellDetails: React.FC<Props> = ({
  variant,
  activeVariantId,
  href,
  active,
  isShoppableEditItem,
  shoppableEditItemSize,
  shoppableEditItemColor,
  shoppableEditItemQty,
  trackHrefClick,
  onSelectColor
}) => {
  const activeVariant = useMemo(
    () => getActiveVariantBy(variant, 'id', activeVariantId),
    [activeVariantId, variant]
  );

  const [colorsActive, setColorsActive] = useState(false);

  const isSpectacularGiftFlagEnabled = useSpectacularGiftFlag();

  return (
    <div
      className={classNames('VariantCellDetails', {
        'VariantCellDetails--resting': !active,
        'VariantCellDetails--without-availability': !activeVariant.label,
        'VariantCellDetails--with-tag': variant.tag
      })}
    >
      {active && (
        <VariantCellAddToBag
          className={classNames('VariantCellDetails__add-to-bag', 'swiper-no-swiping')}
          variant={variant}
          activeVariantId={activeVariant.id}
          href={href}
          isSpectacularGift={isSpectacularGiftFlagEnabled && isSpectacularGift(variant)}
          isShoppableEditItem={isShoppableEditItem}
          shoppableEditItemSize={shoppableEditItemSize}
        />
      )}

      <Link
        className="VariantCellDetails__anchor"
        to={href}
        rel="nofollow noindex"
        onClick={trackHrefClick}
      >
        {activeVariant.label ? (
          <div
            className={`VariantCellDetails__availability ${isSpectacularGiftFlagEnabled && isSpectacularGift(variant) ? 'VariantCellDetails__availability--hidden' : ''}`}
          >
            {activeVariant.label}
          </div>
        ) : (
          !active && (
            <div
              className={`VariantCellDetails__availability ${isSpectacularGiftFlagEnabled && isSpectacularGift(variant) ? 'VariantCellDetails__availability--hidden' : ''}`}
            />
          )
        )}

        <div className="VariantCellDetails__designer-name" title={variant.designerName}>
          {variant.designerName}
        </div>
      </Link>

      {variant.name && (
        <Link className="VariantCellDetails__anchor" to={href} onClick={trackHrefClick}>
          <div className="VariantCellDetails__product-name" title={variant.name}>
            {variant.name}
          </div>
        </Link>
      )}

      <Link
        className="VariantCellDetails__anchor"
        to={href}
        rel="nofollow noindex"
        onClick={trackHrefClick}
      >
        <VariantCellProductPrice variant={variant} activeVariantId={activeVariant.id}>
          <CollectionBadge collections={activeVariant.collections} />
        </VariantCellProductPrice>
      </Link>

      {!active && variant.tag && (
        <div className="VariantCellDetails__badge">
          <Badge>{variant.tag}</Badge>
        </div>
      )}

      {isShoppableEditItem && (
        <>
          <Stack space={2} direction="horizontal" alignItems="center" justifyContent="center">
            <div>Size: {shoppableEditItemSize}</div>
            <div>
              Color:{' '}
              {shoppableEditItemColor
                ? shoppableEditItemColor.charAt(0).toUpperCase() + shoppableEditItemColor.slice(1)
                : ''}
            </div>
          </Stack>

          <div>Qty: {shoppableEditItemQty}</div>
        </>
      )}

      {!isShoppableEditItem && variant.otherVariants.length > 0 && !active && !colorsActive && (
        <Clickable
          className="VariantCellDetails__more-colors"
          onClick={() => setColorsActive(true)}
        >
          + Colors
        </Clickable>
      )}

      {variant.otherVariants.length > 0 && (active || colorsActive) && (
        <VariantCellColorSelector
          variant={variant}
          activeVariantId={activeVariantId}
          onSelectColor={onSelectColor}
          className="swiper-no-swiping"
          href={href}
        />
      )}

      {isShoppableEditItem && (
        <VariantCellAddToBag
          className={classNames('VariantCellDetails__add-to-bag', 'swiper-no-swiping')}
          variant={variant}
          activeVariantId={activeVariant.id}
          href={href}
          isSpectacularGift={isSpectacularGiftFlagEnabled && isSpectacularGift(variant)}
          isShoppableEditItem={isShoppableEditItem}
          shoppableEditItemSize={shoppableEditItemSize}
          shoppableEditItemQty={shoppableEditItemQty}
        />
      )}
    </div>
  );
};

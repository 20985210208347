import React from 'react';
import classNames from 'classnames';
import { Button } from '@moda/om';
import { AddToBagFragment } from '../../generated/types';
import { useAddSetToBag } from '../../hooks/useAddSetToBag';

import './AddToBagButton.scss';

type AddSetToBagButtonProps = {
  className?: string;
  variants: AddToBagFragment[];
  restingText: string;
  editItems?: {
    variantId: number;
    size?: string;
    quantity?: number;
  }[];
};

export const AddSetToBagButton: React.FC<AddSetToBagButtonProps> = ({
  className,
  variants,
  restingText,
  editItems
}) => {
  const { disabled, error, handleAddSetToBag, text } = useAddSetToBag(
    variants,
    restingText,
    editItems
  );

  return (
    <Button
      className={classNames('AddSetToBagButton', className)}
      disabled={disabled}
      secondary={error}
      onClick={handleAddSetToBag}
    >
      {text}
    </Button>
  );
};

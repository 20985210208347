import { gql } from '@apollo/client';
import React, { useReducer, useCallback, useMemo } from 'react';
import classnames from 'classnames';
import isTouchDevice from 'is-touch-device';
import loadable from '@loadable/component';
import { VERTICALS, Vertical } from '@moda/portal-stanchions';
import { VariantCellFragment } from '../../../../generated/types';
import { hrefFor } from '../../../../routers';
import { tc } from '../../../../lib/trackingContext';
import { trackVariantClickHistory } from '../../../../lib/variantClicksHistory';
import { getActiveVariantBy } from '../../../../lib/getActiveVariantBy';
import {
  useHasAccessToProductOrTrunkshow,
  HAS_ACCESS_TO_PRODUCT_FRAGMENT
} from '../../../../hooks/useHasAccessToProductOrTrunkshow';
import { useAdminMode } from '../../../AdminMode/AdminModeProvider';
import {
  useAdminModeProductInfoColor,
  ADMIN_MODE_PRODUCT_INFO_FRAGMENT
} from '../../../AdminMode/AdminModeProductInfo';
import { FAVORITE_FRAGMENT } from '../../../Favorite';
import { Link } from '../../../Link';
import { LazyLoad } from '../../../LazyLoad';
import { ADMIN_PRODUCT_CONTROLS_FRAGMENT } from '../../../AdminMode/AdminProductControls/AdminProductControlsFragment';
import { DEFAULT_SORT_OPTIONS, PRODUCTS_PER_PAGE } from '../../../../constants';
import { usePLPParams } from '../../../../hooks/usePLPParams';
import {
  VariantCellImageGallery,
  VARIANT_CELL_IMAGE_GALLERY_FRAGMENT
} from './VariantCellImageGallery';
import { VariantCellDetails, VARIANT_CELL_DETAILS_FRAGMENT } from './VariantCellDetails';

import './VariantCell.scss';

const FavoriteIcon = loadable(() => import('../../../Favorite'), {
  resolveComponent: exports => exports.FavoriteIcon
});

const AdminModeProductInfo = loadable(() => import('../../../AdminMode/AdminModeProductInfo'), {
  resolveComponent: exports => exports.AdminModeProductInfo
});

const AdminProductControls = loadable(() => import('../../../AdminMode/AdminProductControls'), {
  resolveComponent: exports => exports.AdminProductControls
});

export const VARIANT_CELL_FRAGMENT = gql`
  fragment VariantCellFragment on Variant {
    id
    gender
    productId: product_id
    designerSlug: designer_slug
    productSlug: slug
    masterVariant: master_variants_data {
      id
    }
    otherVariants: other_variants_data {
      id
    }
    objectid
    queryid
    index
    category
    subcategory
    ...HasAccessToProductFragment
    ...VariantCellImageGalleryFragment
    ...VariantCellDetailsFragment
    ...FavoriteFragment
    ...AdminModeProductInfoFragment
    ...AdminProductControlsFragment
  }
  ${HAS_ACCESS_TO_PRODUCT_FRAGMENT}
  ${VARIANT_CELL_IMAGE_GALLERY_FRAGMENT}
  ${VARIANT_CELL_DETAILS_FRAGMENT}
  ${FAVORITE_FRAGMENT}
  ${ADMIN_MODE_PRODUCT_INFO_FRAGMENT}
  ${ADMIN_PRODUCT_CONTROLS_FRAGMENT}
`;

enum Mode {
  Resting,
  Active
}

interface State {
  mode: Mode;
  activeVariantId: string;
}

type Action =
  | { type: 'SET_ACTIVE_VARIANT_ID'; payload: { id: string } }
  | { type: 'SET_MODE'; payload: { mode: Mode } };

interface Props extends React.HTMLAttributes<HTMLDivElement> {
  variant: VariantCellFragment;
  moduleName?: string | null;
  selfPosition?: number;
  isShoppableEditItem?: boolean;
  shoppableEditItemSize?: string;
  shoppableEditItemColor?: string;
  shoppableEditItemQty?: number;
}

const reducer = (state: State, action: Action): State => {
  switch (action.type) {
    case 'SET_MODE':
      return { ...state, mode: action.payload.mode };
    case 'SET_ACTIVE_VARIANT_ID':
      return { ...state, activeVariantId: action.payload.id };
    default:
      return state;
  }
};

export const VariantCell: React.FC<Props> = React.memo(
  ({
    className,
    variant,
    moduleName,
    isShoppableEditItem,
    shoppableEditItemSize,
    shoppableEditItemColor,
    shoppableEditItemQty,
    selfPosition,
    ...rest
  }) => {
    const { hasAccess } = useHasAccessToProductOrTrunkshow(variant);
    const { enabled: adminModeEnabled } = useAdminMode();
    const borderColor = useAdminModeProductInfoColor(variant);

    const {
      params: { page: pageNumber }
    } = usePLPParams(DEFAULT_SORT_OPTIONS);

    const [state, dispatch] = useReducer(reducer, {
      mode: Mode.Resting,
      activeVariantId: variant.id
    });

    const vertical = VERTICALS[variant.gender as Vertical];

    const activeVariant = useMemo(
      () => getActiveVariantBy(variant, 'id', state.activeVariantId),
      [variant, state.activeVariantId]
    );

    const handleSelectColor = useCallback(
      (color: string) => {
        const { id } = getActiveVariantBy(
          variant,
          variant => variant.customColorName || variant.color,
          color
        );

        dispatch({ type: 'SET_ACTIVE_VARIANT_ID', payload: { id } });
      },
      [variant]
    );

    const handleMouseEnter = useCallback(() => {
      if (isTouchDevice() || isShoppableEditItem) return;

      dispatch({ type: 'SET_MODE', payload: { mode: Mode.Active } });
    }, [isShoppableEditItem]);

    const handleMouseLeave = useCallback(() => {
      if (isTouchDevice() || isShoppableEditItem) return;

      dispatch({ type: 'SET_MODE', payload: { mode: Mode.Resting } });
    }, [isShoppableEditItem]);

    const trackHrefClick = useCallback(
      (event?: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
        const position = selfPosition
          ? PRODUCTS_PER_PAGE * (pageNumber - 1) + selfPosition
          : undefined;
        trackVariantClickHistory({ variantId: variant.id, queryId: variant.queryid, position });
        tc.track('Product Clicked', {
          addToScopeOnce: { variant, activeVariant, moduleName, position },
          addToTrackingEvent: {
            label: variant.id,
            index: variant.index,
            eventType: 'click',
            queryID: variant.queryid,
            objectID: variant.objectid,
            position: position ?? null,
            coordinateX: event?.pageX || 0,
            coordinateY: event?.pageY || 0,
            page: window.location.pathname,
            productPosition: selfPosition ? selfPosition + 1 : null
          }
        });
      },
      [selfPosition, pageNumber, variant, activeVariant, moduleName]
    );

    const href = hrefFor.ProductDetailPage({
      vertical,
      designerSlug: variant.designerSlug || '',
      productSlug: variant.productSlug,
      variantId: activeVariant.id
    });

    if (!hasAccess) return null;

    return (
      <div
        className={classnames('VariantCell', className, {
          'VariantCell--active': state.mode === Mode.Active,
          'VariantCell--admin-mode': adminModeEnabled
        })}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        data-variant-pk={variant.id}
        data-product-id={variant.productId}
        {...rest}
      >
        <div className="VariantCell__wrapper" style={{ borderColor }}>
          {adminModeEnabled && (
            <AdminProductControls variant={variant} activeVariantId={activeVariant.id} />
          )}
          <div className="VariantCell__content">
            <Link
              className="VariantCell__image"
              to={href}
              rel="nofollow noindex"
              onClick={event => trackHrefClick(event)}
              aria-label={variant.name || ''}
              data-testid="VariantCell__image"
            >
              <VariantCellImageGallery
                key={activeVariant.id}
                variant={variant}
                activeVariantId={activeVariant.id}
                active={state.mode !== Mode.Resting}
              />
            </Link>

            <LazyLoad>
              <FavoriteIcon className="VariantCell__favorite" variant={variant} />
            </LazyLoad>
          </div>

          <VariantCellDetails
            variant={variant}
            activeVariantId={activeVariant.id}
            href={href}
            active={state.mode === Mode.Active}
            trackHrefClick={trackHrefClick}
            onSelectColor={handleSelectColor}
            isShoppableEditItem={isShoppableEditItem}
            shoppableEditItemColor={shoppableEditItemColor}
            shoppableEditItemSize={shoppableEditItemSize}
            shoppableEditItemQty={shoppableEditItemQty}
          />

          {adminModeEnabled && (
            <AdminModeProductInfo
              className="VariantCell__admin-product-info"
              variant={variant}
              activeVariantId={state.activeVariantId}
            />
          )}
        </div>
      </div>
    );
  }
);
